import Link from 'next/link';
import { IS_DEFAULT_CHANNEL } from '../../lib/config';
import { getI18nDateFormat, getNextDayOfWeek } from '../../lib/date';
import { useTranslation } from '../../lib/i18n';
import { ButtonLink } from '../common/button';
import Loading from '../common/loading';
import { FadeIn } from '../common/transitions';
import { useUser } from '../user-context';
import CartLines from './cart-lines';
import { RemainingForFreeShipping } from './totals-section';

const formatDate = (date, dateText, outputFormat = 'text') =>
  outputFormat === 'text'
    ? dateText
    : outputFormat === 'date'
      ? date
      : getI18nDateFormat(date);

export const useDeliveryDate = ({
  outputFormat = 'text',
  product = undefined,
}) => {
  const { t } = useTranslation();
  const now = new Date();
  const day = now.getDay();
  const hour = now.getHours();

  if (product?.metadata?.deliveryDateText) {
    return t(product.metadata.deliveryDateText);
  }

  if (day >= 1 && day <= 4 && hour < 16) {
    return formatDate(
      new Date(now.getTime() + 24 * 60 * 60 * 1000),
      t('Morgen geliefert'),
      outputFormat,
    );
  } else if (day >= 1 && day <= 3 && hour >= 16) {
    return formatDate(
      new Date(now.getTime() + 2 * 24 * 60 * 60 * 1000),
      t('Übermorgen geliefert'),
      outputFormat,
    );
  }

  return formatDate(
    getNextDayOfWeek(now, 2),
    t('Dienstag geliefert'),
    outputFormat,
  );
};

export const EmptyCart = ({ onClick = undefined, className = '' }) => {
  const { t } = useTranslation();
  return (
    <div className={`${className} mx-auto max-w-xs text-center`}>
      <p className='pb-8'>{t('emptyCartMessage')}</p>
      <ButtonLink
        href='/wein'
        className='my-4 block'
        wide
        filled
        onClick={onClick}
      >
        {t('goToShop')}
      </ButtonLink>
    </div>
  );
};

export const Cart = ({ overlayContext }) => {
  const { t } = useTranslation();
  const { totalItemsCount, checkoutInitialized, userInitialized } = useUser();

  if (!checkoutInitialized || !userInitialized) {
    return <Loading />;
  }

  // if (totalItemsCount == 0) {
  //   return (
  //     <EmptyCart onClick={() => overlayContext.close()} className='mt-8' />
  //   );
  // }

  const aDate = useDeliveryDate({ outputFormat: 'dateString' });

  return (
    <>
      <CartLines>
        {(checkout, delivery) => (
          <FadeIn show={totalItemsCount > 0}>
            {/* {delivery?.hasStandardDelivery ? (
              <div className='mt-6 text-left font-bold'>
                {delivery?.hasCustomDelivery
                  ? t('Teillieferung am {aDate}', { aDate })
                  : t('Lieferung am {aDate}', { aDate })}
              </div>
            ) : (
              <></>
            )} */}
            <div
              className={
                'mt-8 grid gap-2 ' +
                (overlayContext.config.showAddToCart
                  ? 'sm:grid-cols-2'
                  : 'sm:grid-cols-1')
              }
            >
              {overlayContext.config.showAddToCart && (
                <button
                  type='button'
                  className={`rounded-md border border-black px-1 py-4 font-bold uppercase text-black transition duration-200 hover:bg-black hover:text-white`}
                  onClick={() => overlayContext.close()}
                >
                  {t('Weiter einkaufen')}
                </button>
              )}
              <Link
                href={
                  checkout.totalPrice?.gross?.amount
                    ? '/checkout'
                    : '/checkout/review'
                }
                onClick={() => overlayContext.close()}
                className={`rounded-md bg-actionRed px-1 py-4 text-center font-bold uppercase text-white transition duration-200 hover:bg-black`}
                type='submit'
              >
                {checkout.totalPrice?.gross?.amount
                  ? t('goToCheckout')
                  : t('go-to-free-checkout')}
              </Link>
            </div>
            <RemainingForFreeShipping className='mt-8' checkout={checkout} />
            {IS_DEFAULT_CHANNEL && (
              <img
                className='mt-8 w-full text-center'
                alt=''
                src='/static/payments-small-invoice.svg'
              />
            )}
          </FadeIn>
        )}
      </CartLines>
    </>
  );
};
