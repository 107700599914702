import { Field, useField, useFormikContext } from 'formik';
import { Fragment, useState } from 'react';
import { useTranslation } from '../../lib/i18n';
import { only, scrollIntoViewIfNeeded } from '../../lib/utils';
import { Button } from '../common/button';

export const ErrorText = ({ className = '', children }) => (
  <div className={`mt-[3px] text-sm text-red-500 ${className}`}>{children}</div>
);

export const NumberInput = ({
  label,
  onValueChange,
  useFormik = true,
  className = 'w-10/12 md:w-4/5',
  compact = false,
  minValue = 1,
  incValue = 1,
  borderSize = 1,
  maxValue = Infinity,
  labelClassName = 'font-bold mb-2 block',
  ariaLabelOnly = false,
  ...props
}) => {
  const { t } = useTranslation();
  const [field, meta] = useFormik
    ? // @ts-ignore
      useField(props)
    : [{ value: props.value ?? minValue }, {}];

  const [disabled, setDisabled] = useState(false);

  const propagateValueChange = (n) => async () => {
    if (!disabled) {
      setDisabled(true);
      await onValueChange(n);
      setDisabled(false);
    }
  };

  const btnMinusClick = propagateValueChange(
    Math.max(field.value - incValue, minValue),
  );

  const btnPlusClick = propagateValueChange(
    Math.min(field.value + incValue, maxValue),
  );

  const disabledClass = 'opacity-20 pointer-events-none';
  const minusDisabledClass = field.value <= minValue ? disabledClass : '';
  const plusDisabledClass = field.value >= maxValue ? disabledClass : '';

  const borderClass = 'border border-input';

  const buttonClass = `px-3 text-center w-[30px] h-[30px] rounded-md ${borderClass} transition duration-200
        hover:bg-black hover:text-white focus:outline-none focus:shadow-outline`;

  // @ts-ignore
  field.onBlur = only(field.onBlur);
  // @ts-ignore
  field.onChange = only(field.onBlur);
  // @ts-ignore
  field.onClick = only(() => {});

  return (
    <div className={className}>
      {!ariaLabelOnly && (
        <label className={labelClassName} htmlFor={props.id || props.name}>
          {label}
        </label>
      )}
      <div className='flex gap-1'>
        <button
          type='button'
          aria-label={t('minusOne')}
          onClick={only(btnMinusClick)}
          className={`${buttonClass} ${minusDisabledClass}`}
        >
          <span className='-ml-[1px] mt-[1px]'>-</span>
        </button>
        <input
          aria-label={label}
          className={`
            h-[30px]
            w-full
            flex-grow
            appearance-none 
            rounded-md
            py-1
            text-center
            focus:outline-none
            ${disabled ? 'text-gray-200' : ''}
            ${
              // @ts-ignore
              meta.error ? 'has-error border border-red-500' : borderClass
            }
                    `}
          {...field}
          {...props}
        />
        <button
          type='button'
          aria-label={t('plusOne')}
          onClick={only(btnPlusClick)}
          className={`${buttonClass} ${plusDisabledClass}`}
        >
          <span className='-ml-[1px] mt-[1px]'>+</span>
        </button>
      </div>
    </div>
  );
};

export const BirthdayField = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div
        className='element-sublink mt-2'
        dangerouslySetInnerHTML={{
          __html: t('birthday-hint'),
        }}
      ></div>
      <TextInput
        autoComplete='bday'
        name='birthday'
        type='date'
        label={t('birthday')}
        {...props}
      />
    </div>
  );
};

export const TextInput = ({
  label = '',
  placeholder = label,
  className = '',
  labelClassName = 'font-bold',
  inputClassName = 'w-full',
  ariaLabelOnly = false,
  manualErrorMessage = '',
  compact = false,
  onChange = undefined,
  ...props
}) => {
  // @ts-ignore
  const [field, meta] = useField(props);
  const FieldElement = props.rows ? 'textarea' : 'input';

  const hasError =
    (meta.touched && meta.error && meta.error !== 'None') || manualErrorMessage;

  if (onChange) {
    const _handlerOnChange = field.onChange;
    field.onChange = (e) => {
      _handlerOnChange(e);
      onChange(e);
    };
  }

  return (
    <div className={className}>
      <FieldElement
        aria-label={label}
        className={`text-input
                    ${props.rows ? 'text-area' : ''}
                    ${compact ? 'compact' : ''}
                    ${
                      props.disabled
                        ? 'border-gray-400'
                        : hasError
                          ? 'has-error border-red-500 placeholder-red-500'
                          : 'border-0.6 placeholder-inputPlaceholder'
                    }
                    ${inputClassName}
                `}
        placeholder={placeholder}
        {...field}
        {...props}
      />

      {hasError ? (
        <ErrorText>{meta.error || manualErrorMessage}</ErrorText>
      ) : null}
    </div>
  );
};

export const PasswordInput = ({
  className = '',
  id = 'password',
  labelClassName = 'font-bold',
  inputClassName = 'w-full',
  ariaLabelOnly = false,
  manualErrorMessage = '',
  compact = false,
  onChange = undefined,
  ...props
}) => {
  // @ts-ignore
  const [field, meta] = useField(props);
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();

  const hasError =
    (meta.touched && meta.error && meta.error !== 'None') || manualErrorMessage;

  if (onChange) {
    const _handlerOnChange = field.onChange;
    field.onChange = (e) => {
      _handlerOnChange(e);
      onChange(e);
    };
  }

  const label = t('password');

  return (
    <div className={className}>
      <input
        aria-label={label}
        className={`text-input
                    ${props.rows ? 'text-area' : ''}
                    ${compact ? 'compact' : ''}
                    ${
                      props.disabled
                        ? 'border-gray-400'
                        : hasError
                          ? 'has-error border-red-500 placeholder-red-500'
                          : 'border-0.6 placeholder-inputPlaceholder'
                    }
                    ${inputClassName}
                `}
        placeholder={label}
        autoComplete='password'
        type={showPassword ? 'text' : 'password'}
        id={id}
        {...field}
        {...props}
      />

      <label className='flex cursor-pointer items-start hover:text-green mt-2'>
        <input
          name='showPassword'
          type='checkbox'
          className='form-checkbox mt-[2px] border text-green focus:ring-0'
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        />

        <div className='ml-2'>
          <span className='element-sublink'>{t('passwort_anzeigen')}</span>
        </div>
      </label>

      {hasError ? (
        <ErrorText>{meta.error || manualErrorMessage}</ErrorText>
      ) : null}
    </div>
  );
};

export const CheckBox = ({ label, className = '', ...props }) => {
  // @ts-ignore
  const [field, meta] = useField(props);
  const hasError = meta.touched && meta.error && meta.error !== 'None';

  return (
    <div className={`${className} ${hasError ? 'has-error' : ''}`}>
      <label className='flex cursor-pointer items-start hover:text-green'>
        <Field
          type='checkbox'
          name={field.name}
          className='form-checkbox mt-[2px] border text-green focus:ring-0'
        />
        <div className='ml-2'>
          <span
            className='element-sublink'
            dangerouslySetInnerHTML={{ __html: label }}
          ></span>
          {hasError ? (
            <ErrorText className='ml-0'>{meta.error}</ErrorText>
          ) : null}
        </div>
      </label>
    </div>
  );
};

export const RadioGroup = ({
  choices,
  className = '',
  name,
  onClick = null,
  noWrapper = false,
}) => {
  const Wrapper = noWrapper ? Fragment : 'div';
  const props = noWrapper
    ? {}
    : { className: `${className} flex flex-col gap-2` };

  return (
    <Wrapper {...props}>
      {choices.map(({ value, label }, key) => (
        <label
          key={key}
          className={`${name}-${value} flex cursor-pointer items-start hover:text-green`}
        >
          <Field
            onClick={onClick}
            type='radio'
            value={value}
            name={name}
            className='mr-2 mt-[2px] h-4 w-4 border-black text-green focus:ring-2 focus:ring-transparent'
          />
          <span className='' dangerouslySetInnerHTML={{ __html: label }} />
        </label>
      ))}
    </Wrapper>
  );
};

export const FormikSubmitButton = ({
  className = '',
  children,
  loading = false,
  ...props
}) => {
  const { validateForm, setErrors, setTouched, handleSubmit } =
    useFormikContext();

  const submitOrScrollToError = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      // @ts-ignore
      setTouched(errors);
      scrollIntoViewIfNeeded(document.querySelector('.has-error'), {
        behavior: 'smooth',
      });
    } else {
      handleSubmit();
    }
  };

  return (
    <Button
      filled={true}
      className={className}
      onClick={only(submitOrScrollToError)}
      loading={loading}
      {...props}
    >
      {children}
    </Button>
  );
};
