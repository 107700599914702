import { useEffect, useState } from 'react';
import dropDownSvg from '../../img/dropdown.svg';
import { useTranslation } from '../../lib/i18n';
import { useFilter } from '../filter/filter-context';
import { RangeSlider, chfTipFormatter } from '../slider/range';

const TextFilterCheckbox = ({
  label,
  parameter,
  name,
  current,
  onChange,
  available = true,
}) => {
  const [checked, setChecked] = useState(current?.indexOf(parameter) > -1);

  useEffect(() => {
    if (checked !== current?.indexOf(parameter) > -1) {
      setChecked(current?.indexOf(parameter) > -1);
    }
  }, [current?.indexOf(parameter) > -1]);

  const handleChange = (e) => {
    const checked = e.target.checked;
    setChecked(checked);
    onChange(checked);
  };

  return (
    <label
      className={`${
        available || checked ? '' : 'opacity-40'
      } flex cursor-pointer items-center py-1 transition duration-100 hover:text-green lg:py-0 lg:pb-1`}
    >
      <>
        <input
          onChange={handleChange}
          checked={checked}
          type='checkbox'
          name={name}
          className={`-mt-[3px] mr-3 h-4 w-4 rounded border-gray-300 text-green focus:ring-transparent`}
        />

        {label}
      </>
    </label>
  );
};

const TextFilter = ({
  label,
  items,
  index,
  current,
  expandable = true,
  updateImmediately,
}) => {
  const {
    addFilter,
    removeFilter,
    state: {
      aggregations: { aggregations },
    },
  } = useFilter();
  const { t } = useTranslation();
  const [localItems, setLocalItems] = useState(items);
  const [filterInput, setFilterInput] = useState('');
  const [expanded, setExpanded] = useState(!expandable);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const changeFilterInput = (e) => {
    const value = e.target.value;
    setFilterInput(value);
    setLocalItems(
      items.filter((item) =>
        item.label?.toLowerCase().includes(value?.toLowerCase()),
      ),
    );
  };

  const resetFilterInput = () => {
    if (filterInput) {
      setFilterInput('');
      setLocalItems(items);
    }
  };

  return (
    <>
      <div className={`filter-header filter-header-${index} lg:max-w-[180px]`}>
        <a onClick={toggleExpanded} role='button'>
          <h3
            className={`${
              !expanded ? 'mb-3 border-b border-0.3 lg:mb-0' : ''
            } mt-1 flex w-full items-center justify-between pb-2 text-base lg:mt-0 lg:border-none lg:pb-1`}
          >
            {label}
            {expandable && (
              <img
                alt=''
                src={dropDownSvg}
                className={`h-[5px] justify-self-end text-right lg:hidden lg:pr-0 ${
                  expanded ? 'rotate-180 transform' : ''
                }`}
              />
            )}
          </h3>
        </a>
        {expandable && (
          <div
            className={`${expanded ? '' : 'hidden lg:block'} relative w-full`}
          >
            <input
              value={filterInput}
              onChange={changeFilterInput}
              placeholder={t('Filter')}
              className='block h-[26px] w-full rounded-[6px] border border-0.6 px-2 focus:border-green focus:ring-green lg:text-[13px]'
            />
            {filterInput && (
              <button
                className='absolute right-2 top-2'
                onClick={(e) => {
                  e.preventDefault();
                  resetFilterInput();
                }}
              >
                <svg width='11' height='11' viewBox='0 0 11 11' fill='none'>
                  <path d='M1 10L10 1' stroke='#231F20' strokeMiterlimit='10' />
                  <path d='M10 10L1 1' stroke='#231F20' strokeMiterlimit='10' />
                </svg>
              </button>
            )}
          </div>
        )}
      </div>

      <ul
        className={`filter-body filter-body-${index} lg:block lg:max-w-[180px] ${
          expanded ? 'block' : 'hidden'
        }`}
      >
        {localItems.map(({ label, parameter }) => {
          const name = `${index}-${parameter}-${updateImmediately}`;
          return (
            <li key={name}>
              <TextFilterCheckbox
                label={label}
                parameter={parameter}
                name={name}
                current={current}
                available={
                  aggregations ? aggregations.indexOf(parameter) > -1 : true
                }
                onChange={(checked) => {
                  if (checked) {
                    addFilter({
                      filter: parameter,
                      label,
                      updateImmediately: updateImmediately,
                    });
                  } else {
                    removeFilter({
                      filter: parameter,
                      updateImmediately: updateImmediately,
                    });
                  }
                  resetFilterInput();
                }}
              />
            </li>
          );
        })}
      </ul>
    </>
  );
};

const ShortcutFilter = ({
  label,
  items,
  index,
  current,
  updateImmediately,
}) => {
  const { addFilter, removeFilter } = useFilter();

  return (
    <>
      <div className={`filter-header filter-header-${index} lg:max-w-[180px]`}>
        <h3 className='mt-1 flex w-full items-center justify-between pb-2 text-base lg:mt-0 lg:border-none lg:pb-1'>
          {label}
        </h3>
      </div>
      <ul
        className={`filter-body filter-body-${index} lg:block lg:max-w-[180px] `}
      >
        {items.map(({ label, parameter, icon }, key) => (
          <li key={key}>
            <TextFilterCheckbox
              label={label}
              parameter={parameter}
              name={`${index}-${parameter}-${updateImmediately}`}
              current={current}
              onChange={(checked) => {
                if (checked) {
                  addFilter({
                    filter: parameter,
                    label,
                    updateImmediately: updateImmediately,
                  });
                } else {
                  removeFilter({
                    filter: parameter,
                    updateImmediately: updateImmediately,
                  });
                }
              }}
            />
          </li>
        ))}
      </ul>
    </>
  );
};

const NumberFilter = ({
  label,
  attribute,
  minimum,
  maximum,
  index,
  updateImmediately,
  current,
  unit = 'CHF',
}) => {
  const { addFilter } = useFilter();

  return (
    <div
      className={`filter-header font-medium focus:outline-none lg:max-w-[185px] filter-header-${index}`}
    >
      <h3 className='pb-1 text-base'>{label}</h3>
      <div className='mt-2'>
        <RangeSlider
          className='w-full'
          tipFormatter={chfTipFormatter}
          min={minimum}
          max={maximum}
          low={minimum}
          high={maximum}
          onChange={(value) => {
            addFilter({
              label,
              filter: attribute,
              value: `${attribute}--${value[0]}f-t${value[1]}`,
              updateImmediately,
            });
          }}
          tooltipVisible={false}
          current={current}
          attribute={attribute}
          unit={unit}
        />
      </div>
    </div>
  );
};

export const filterTypes = {
  text_filters: TextFilter,
  number_filter: NumberFilter,
  shortcutfilter: ShortcutFilter,
};
