import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import { useTranslation } from '../../lib/i18n';

const Dummy = () => <div className='mt-4 text-base md:mt-8' />;

const Detail = () => {
  const { t } = useTranslation();
  return (
    <div
      className='py-10'
      dangerouslySetInnerHTML={{
        __html: t('bannerOrderHint'),
      }}
    />
  );
};

const BaseBanner = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(true);
  };

  return (
    <>
      <div
        role='alert'
        className={`block cursor-pointer bg-green text-center text-white`}
        onClick={onClick}
      >
        <div className='mx-auto flex max-w-7xl items-center justify-center leading-5 md:pb-0'>
          <p className='banner pl-2 pb-[4px] pt-[4px]'>
            {t('banner_delivery')}
          </p>

          <button
            role='button'
            className='cursor-pointer px-3 py-1 text-xl font-bold'
            aria-label={t('readMore')}
          >
            +
          </button>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as='div' className='relative z-50' onClose={setOpen}>
          <div className='fixed inset-0 overflow-hidden'>
            <div className='absolute inset-0 overflow-hidden'>
              <div className='pointer-events-none fixed inset-y-0 left-0 flex max-w-full'>
                <Transition.Child
                  as={Fragment}
                  enter='transform transition ease-in-out duration-300 sm:duration-500'
                  enterFrom='-translate-x-full'
                  enterTo='-translate-x-0'
                  leave='transform transition ease-in-out duration-300 sm:duration-500'
                  leaveFrom='-translate-x-0'
                  leaveTo='-translate-x-full'
                >
                  <Dialog.Panel className='pointer-events-auto relative w-screen md:max-w-1/2'>
                    <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl'>
                      <button
                        type='button'
                        className='absolute right-5 top-5 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none md:right-10 md:top-10'
                        onClick={() => setOpen(false)}
                      >
                        <span className='sr-only'>Close panel</span>
                        <svg
                          width='21'
                          height='21'
                          viewBox='0 0 21 21'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M1 19.8687L19.8687 1'
                            stroke='#231F20'
                            strokeWidth='1.5'
                          />
                          <path
                            d='M19.8687 19.8687L1 1'
                            stroke='#231F20'
                            strokeWidth='1.5'
                          />
                        </svg>
                      </button>
                      <div className='md: mx-auto flex max-w-sm flex-1 flex-col justify-center px-4 py-10 md:px-0 md:py-0'>
                        <Detail />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export const Banner = React.memo(BaseBanner);
